<template>
  <div class="container py-8">
    <div class="card py-2 border-light col-12 col-md-8 col-lg-6 mx-auto">
      <div class="card-body">
        <form @submit.prevent="resetUserPassword">
          <div
            class="row flex-column mb-3 align-items-center justify-content-center"
          >
            <div class="col-md-12 mb-3">
              <div class="input-group">
                <input
                  type="password"
                  class="form-control border-end-0"
                  required
                  autocomplete="new-password"
                  placeholder="New Password"
                  v-model="password"
                />
                <label class="input-group-text">
                  <i class="bi bi-lock"></i>
                </label>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <div class="input-group">
                <input
                  ref="passConfirm"
                  type="password"
                  class="form-control border-end-0"
                  required
                  autocomplete="new-password"
                  placeholder="Confirm Password"
                  v-model="password_confirmation"
                  :oninput="checkPassConfirmation"
                />
                <label class="input-group-text">
                  <i class="bi bi-lock"></i>
                </label>
              </div>
            </div>
          </div>
          <div v-if="status_type === 'error'" class="text-danger">
            {{ message }}
          </div>
          <div
            class="mt-3 row justify-content-center align-items-center px-3 px-lg-4"
          >
            <button type="submit" class="col-12 btn btn-auth text-white">
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
export default {
  data() {
    return {
      token: null,
      email: "",
      password: "",
      password_confirmation: ""
    }
  },
  mounted() {
    this.token = this.$route.query.token
    this.email = this.$route.query.email
  },
  methods: {
    ...mapActions("auth", ["resetPassword"]),
    resetUserPassword() {
      const payload = {
        token: this.token,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation
      }
      this.resetPassword(payload)
    },
    checkPassConfirmation() {
      let passConfirm = this.$refs.passConfirm

      if (this.password !== this.password_confirmation) {
        let message = "Password confirmation must match the password"
        passConfirm.setCustomValidity(message)
      } else {
        passConfirm.setCustomValidity("")
      }
    }
  },
  computed: {
    ...mapState("status", ["status_type", "message"])
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/auth";
.form-control {
  height: 50px;
}

.btn-auth {
  height: 50px;
}
</style>
